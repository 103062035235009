@import "~bootstrap/scss/bootstrap";

body {
  margin-top: 4.5rem;
  margin-bottom: 2rem;
}

.navbar {
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url('./hamburger.svg')
}

.no-border-right {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.no-border-left {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.no-border-sides {
  border-right: none;
  border-left: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.router-link:link,
.router-link:visited,
.router-link:hover,
.router-link:active {
  color: black;
  text-decoration: none;
}

.list-item-row {
  @include media-breakpoint-down(xs) {
    margin-right: -45px
  }
}

.list-item-multi-divider {
  @include media-breakpoint-down(xs) {
    margin-right: -22px;
  }

  @include media-breakpoint-up(sm) {
    margin-right: -15px;
  }

  border-right: 1px solid lightgray;
  height: 100%;
}

.list-item-title {
  @include media-breakpoint-down(xs) {
    margin-left: 1rem;
  }
}

.list-item-buttons {
  @include media-breakpoint-up(sm) {
    margin-right: 33px;
  }
  @include media-breakpoint-up(xs) {
    margin-right: 2px;
  }
}

.list-multi-created-at {
  @include media-breakpoint-up(lg) {
    margin-right: 31px;
    margin-left: -64px;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 38px;
    margin-left: -71px;
  }

  @include media-breakpoint-up(xxl) {
    margin-right: 47px;
    margin-left: -80px;
  }
}

.list-multi-buttons {
  @include media-breakpoint-down(lg) {
    margin-left: -33px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: -2px;
  }
}

#loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

#spinner {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
}

// TODO: can these be combined?
.list-item-list-group-item {
  display: block;
}

.list-list-group-item {
  display: block;
}

.refused-list-list-group-item {
  display: block;
}

.users-list-list-group-item {
  display: block;
}

.multi-select-check {
  position: absolute;
  top: 40%;
  left: 4%;
}

// TODO: compare ListForm and ListItemForm, any others?
#form-collapse {
  padding: 7;
}

#filter-title {
  line-height: 2.5rem;
  margin-right: 1rem;
}

#filter-button {
  margin-right: 1rem;
}

#no-filter {
  cursor: not-allowed;
}

.list-button-disabled {
  pointer-events: none;
  opacity: 0.3;
}

.list-button-enabled {
  pointer-events: auto;
  opacity: 1;
}

.merge-modal-confirm-enabled {
  pointer-events: auto;
}

.merge-modal-confirm-disabled {
  pointer-events: none;
}
